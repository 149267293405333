<div class="shell-page flex flex-column h-full overflow-hidden">

  <!-- Show the breadcrumb on the root shell page, but only when children pages are open -->
  @if (parentShellPage == null && (hasNestedShells$ | async)) {
    <header>
      <p-breadcrumb [model]="(breadcrumbMenuModel$ | async) || []"
      ></p-breadcrumb>
    </header>
  }


  @if (!emptyShell() && !(hasNestedShells$ | async)) {
    <!-- Show the shell page title / toolbars on the leaf shell pages -->
    <header class="flex-grow-0 flex align-items-center">

      @if (showTitle()) {
        <h1 class="mx-2  overflow-hidden text-overflow-ellipsis white-space-nowrap"
            [style]="{flex: '0 1 auto', minWidth: '15rem', maxWidth: 'calc(40%)'}"
        >
          @if (titleTemplateRef) {
            <ng-container [ngTemplateOutletContext]="{}"
                          [ngTemplateOutlet]="titleTemplateRef"
            ></ng-container>
          } @else {
            {{ effectiveShellItem()?.label$ | async }}
          }
        </h1>
      }

      @if (toolbarLeftTemplateRef) {
        <div class="mx-2 overflow-hidden flex align-items-center relative"
             [style]="{flex: '1 0 auto'}"
        >
          <ng-container [ngTemplateOutlet]="toolbarLeftTemplateRef"></ng-container>
        </div>
      }

      <div class="spacer flex-1"></div>

      @if (toolbarRightTemplateRef) {
        <div class="px-2 overflow-hidden flex align-items-center relative"
             [style]="{flex: '1 0 auto'}"
        >
          <ng-container [ngTemplateOutlet]="toolbarRightTemplateRef"></ng-container>
        </div>
      }

    </header>
  }

  <div class="content flex-1 relative overflow-auto">
    <ng-content></ng-content>
  </div>

</div>
