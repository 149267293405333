import {FilterPropertyValue, NitroUserFilterProperties, NitroUserFilterProperty} from "@fiscalteam/ngx-nitro-services";
import {WsNitroUserSearch} from "@fiscalteam/nitro-domain-client";

export const wellKnownNitroUserFilter: any & Record<string, FilterPropertyValue<NitroUserFilterProperty, WsNitroUserSearch, any>[]> = {
  active : [
    {
      property: NitroUserFilterProperties.active,
      filterValue: true,
    }
  ]
}
