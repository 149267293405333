import {AccountingDataDashboardRouteComponent} from "./accounting-data-dashboard-route/accounting-data-dashboard-route.component";
import {Route} from "@angular/router";

export const AdminFrontRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: AccountingDataDashboardRouteComponent,
  },
  {
    path: 'batch',
    loadChildren: () => import('../batch/batch-routes').then(routes => routes.BatchRoutes),
  },
  {
    path: 'user',
    loadChildren: () => import('../nitro-user/nitro-user-routes').then(routes => routes.NitroUserRoutes),
  },
  {
    path: 'financial-account-provider',
    loadChildren: () => import('../financial-account-provider/financial-account-provider-routes').then(mod => mod.FinancialAccountProviderRoutes)
  },
  {
    path: 'business-sector',
    loadChildren: () => import('../business-sector/business-sector-routes').then(routes => routes.BusinessSectorRoutes),
  },
  {
    path: 'configuration',
    loadChildren: () => import('../configuration/configuration-routes').then(routes => routes.ConfigurationRoutes),
  },
  {
    path: 'customer',
    loadChildren: () => import('../customer/customer-routes').then(routes => routes.CustomerRoutes),
  },
  {
    path: 'customerTransaction',
    loadChildren: () => import('../customer-transaction/customer-transaction-routes').then(routes => routes.CustomerTransactionRoutes),
  },
  {
    path: 'customerDocument',
    loadChildren: () => import('../customer-document/customer-document-routes').then(routes => routes.CustomerDocumentRoutes),
  },
  {
    path: 'document-type-collection',
    loadChildren: () => import('../document-type-collection/document-type-collection-routes').then(routes => routes.DocumentTypeCollectionRoutes),
  },
  {
    path: 'problem',
    // TODO: rename document-problem to accounting-data-problem
    loadChildren: () => import('../document-problem/document-problem-routes').then(routes => routes.AccountingDataProblemRoutes),
  },
  {
    path: 'rule',
    loadChildren: () => import('../rule/rule-routes').then(routes => routes.RuleRoutes),
  },
  {
    path: 'ruleAction',
    loadChildren: () => import('../rule-action/rule-action-routes').then(routes => routes.RuleActionRoutes),
  },
  {
    path: 'thirdparty',
    loadChildren: () => import('../thirdparty/thirdparty-routes').then(routes => routes.ThirdpartyRoutes),
  },
  {
    path: 'thirdpartyEntity',
    loadChildren: () => import('../thirdparty-entity/thirdparty-entity-routes').then(routes => routes.ThirdpartyEntityRoutes),
  },
  {
    path: 'thirdpartyEntityIdentifier',
    loadChildren: () => import('../thirdparty-entity-identifier/thirdparty-entity-identifier-routes').then(routes => routes.ThirdpartyEntityIdentifierRoutes),
  },
  {
    path: 'trustee',
    loadChildren: () => import('../trustee/trustee-routes').then(routes => routes.TrusteeRoutes),
  },
  {
    path: 'documentRule',
    loadChildren: () => import('../document-rule/document-rule-routing.module').then(mod => mod.DocumentRuleRoutingModule)
  },
  {
    path: 'role',
    loadChildren: () => import('../customer-thirdparty-entity-role/customer-thirdparty-entity-role-routing.module').then(mod => mod.CustomerThirdpartyEntityRoleRoutingModule)
  },
];
