import {Component, computed, ContentChild, effect, inject, input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ShellPageTitleDirective} from "../shell-page-title.directive";
import {ShellHeaderToolbarLeftDirective} from "../shell-header-toolbar-left.directive";
import {ShellHeaderToolbarRightDirective} from "../shell-header-toolbar-right.directive";
import {ActivatedRoute} from "@angular/router";
import {Observable, of} from "rxjs";
import {MenuItem} from "primeng/api";
import {AppShellItem, AppShellRoutingService} from "@fiscalteam/ngx-nitro-services";
import {ADMIN_SHELL_PAGE_INFO_ROUTE_DATA_PARAMETER, AdminShellPageInfo, LazyAdminShellPageInfo} from "../admin-shell-page-info";
import {AdminFrontContextService} from "../context/admin-front-context.service";
import {AdminFrontAppContext} from "../context/admin-front-app-context";
import {updatePrimaryPalette} from "@primeng/themes";

@Component({
  selector: 'adm-app-shell-page',
  templateUrl: './app-shell-page.component.html',
  styleUrls: ['./app-shell-page.component.scss'],
  providers: [],
  standalone: false
})
export class AppShellPageComponent implements OnInit, OnDestroy {

  shellRoutingService = inject(AppShellRoutingService);
  adminFrontContextService = inject(AdminFrontContextService);
  activatedRoute = inject(ActivatedRoute);
  parentShellPage = inject(AppShellPageComponent, {
    skipSelf: true,
    optional: true,
  });

  /**
   * When the page is a 'shell' that should be available in the navigation breadcrumb,
   * this parameter must be set to provide the information to display
   */
  shellPageInfo = input<AdminShellPageInfo | LazyAdminShellPageInfo>();

  /**
   * @deprecated use shellPageInfo instead
   */
  shellItem = input<AppShellItem>();

  /**
   * When true, the title will not be displayed. Toolbars left & right are still included, but may use less vertical space.
   *
   * This may be used for page which displays lots of tabs for instance, in order to keep horizontal space available
   */
  showTitle = input(true)

  /**
   * Whether the shell should not be accounted for in the hierarchy.
   *
   * This is useful for shells that acts as empty wrappers, like module shells.
   * With this flag, the header of this shell will not be displayed, and when the shell is nested in another one,
   * the parent shell will still display its header.
   */
  emptyShell = input(false);

  /**
   * When the shell page needs to put a resource in the context of the application, it can
   * set the context key and its value in here.
   *
   * For instance, a CustomerDetailsShell page could fetch a customer from the id provided in the route url,
   * and set it in the app context, so that a child page 'TransactionList' could filter the transactions for this
   * customer only.
   *
   * The context will be cleared once the user navigates away from this page.
   */
  appContextKey = input<keyof AdminFrontAppContext>();
  /**
   * The value to put in the app context, under the key provided by appContextKey.
   */
  appContextValue = input<any>();

  @ContentChild(ShellPageTitleDirective, {
    read: TemplateRef
  })
  titleTemplateRef?: TemplateRef<any>;
  @ContentChild(ShellHeaderToolbarLeftDirective, {
    read: TemplateRef
  })
  toolbarLeftTemplateRef?: TemplateRef<any>;
  @ContentChild(ShellHeaderToolbarRightDirective, {
    read: TemplateRef
  })
  toolbarRightTemplateRef?: TemplateRef<any>;


  hasNestedShells$: Observable<boolean> = of(false);
  breadcrumbMenuModel$: Observable<MenuItem[]> = of([]);
  uniqueShellId = this.createUniqueId();

  effectiveShellItem = computed(() => {
    const legacyShellItem = this.shellItem();
    const shellPageInfo = this.shellPageInfo();
    const emptyShell = this.emptyShell();
    return this.createShellItem(legacyShellItem, shellPageInfo, emptyShell);
  });

  updateAppContext =  effect(() => {
    const contextKey = this.appContextKey();
    const contextValue = this.appContextValue();
    if (contextKey && contextValue) {
      this.adminFrontContextService.patchContext(contextKey, contextValue);
    }
  });

  ngOnInit(): void {
    const shellItem = this.effectiveShellItem();
    if (shellItem) {
      this.shellRoutingService.onShellInit(shellItem, this.activatedRoute);
      this.hasNestedShells$ = this.shellRoutingService.hasNestedShell$(shellItem);
      this.breadcrumbMenuModel$ = this.shellRoutingService.breadcrumbMenuModel$;
    }
  }

  ngOnDestroy() {
    const shellItem = this.effectiveShellItem();
    if (shellItem) {
      this.shellRoutingService.onShellDestroy(shellItem);
    }

    const contextKey = this.appContextKey();
    if (contextKey) {
      this.adminFrontContextService.patchContext(contextKey, null);
    }
  }

  /**
   * Create an AppShellItem providing information to display in the breadcrumb, and dictating how the app-shell
   * page and the breadcrumb will be altered
   *
   * @param legacyShellItem An AppShellItem instance that was provided using the deprecated shellItem input
   * @param shellPageInfo An AdminShellPageInfo instance that was provided by the shellPageInfo input
   * @param emptyShell A flag indicating that this shell should not be part of the navigation hierarchy/breadcrumb
   * @private
   * @return an AppShellItem instance that is registered with the shellRoutingService on component init, and removed
   * on component destroy. This allows the service to keep track of the page hierarchy as the user navigates through
   * the app.
   */
  private createShellItem(legacyShellItem: AppShellItem | undefined,
                          shellPageInfo: AdminShellPageInfo | LazyAdminShellPageInfo | undefined,
                          emptyShell: boolean): AppShellItem | undefined {
    // We iterate over the different sources until we find a source.
    // In newest APIs (eg AdminShellPageInfo rather than AppShellItem directly), we have to generate the shellid
    // in here rather than relying on a value provided by the deveveloper using this component. It is important that
    // this id is unique and constant throughout the lifetime of this component.
    if (shellPageInfo) {
      // First case: the user provided an AdminShellPageInfo instance using this component input
      if (this.isLazyPageInfo(shellPageInfo)) {
        // This interface allows 'lazy' info to be provided as observables, for instance if the label/icon depends
        // on a resource that must be fetched from the server. Typical for details pages.
        return {
          shellId: this.uniqueShellId,
          label$: shellPageInfo.label$,
          icon$: shellPageInfo.icon$,
          route: this.activatedRoute,
          nesting: !emptyShell
        };
      } else {
        // This interface also support 'static' info, where the values are hardcoded in the code. Typical for list routes
        return {
          shellId: this.uniqueShellId,
          label$: of(shellPageInfo.label),
          icon$: of(shellPageInfo.icon),
          route: this.activatedRoute,
          nesting: !emptyShell
        };
      }
    } else if (legacyShellItem) {
      // Next case: the user provided a legacy AppShellItem using this component input. This is the same api than that
      // used by the shellRoutingService in the library, so we can use it directly
      return legacyShellItem;
    } else {
      // Next case: the user did not provide any input. We then check for the activated route data, as 'static'
      // AdminShellPageInfo can be provided directly in there (in the route definition).
      const routeData = this.activatedRoute.snapshot.data;
      if (ADMIN_SHELL_PAGE_INFO_ROUTE_DATA_PARAMETER in routeData) {
        const routeDataInfo = routeData[ADMIN_SHELL_PAGE_INFO_ROUTE_DATA_PARAMETER];
        if (this.isPageInfo(routeDataInfo)) {
          return {
            shellId: this.uniqueShellId,
            label$: of(routeDataInfo.label),
            icon$: of(routeDataInfo.icon),
            route: this.activatedRoute,
            nesting: !emptyShell
          };
        }
      }
    }

    // Finally, the user may choose to use this component without providing any 'shell item' to display in the beadcrumb.
    // There are legitimate use case for this, so the warning is not logged; but this can be useful to enable during
    // debugging.
    // console.warn(`Shell page at ${this.activatedRoute.snapshot.url.toString()} did not provide any page info`);
    return undefined;
  }

  private createUniqueId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  private isLazyPageInfo(shellPageInfo: AdminShellPageInfo | LazyAdminShellPageInfo): shellPageInfo is LazyAdminShellPageInfo {
    return 'lazy' in shellPageInfo;
  }

  private isPageInfo(data: any): data is AdminShellPageInfo {
    return 'label' in data && 'icon' in data;
  }
}
